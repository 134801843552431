import { ArrowDown2 } from "iconsax-react";
import { useEffect } from "react";
import {
  Button,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectProps,
  SelectValue,
} from "react-aria-components";

import { ServiceStatusEnum, cn } from "@/shared/utils";

export interface ServiceStatusProps
  extends Omit<SelectProps<typeof Select>, "onSelectionChange"> {
  showLabel?: boolean;
  onSelectionChange?: (status?: ServiceStatusEnum) => void;
}

export function ServiceStatusFilter({
  className,
  showLabel,
  onSelectionChange,
  ...props
}: ServiceStatusProps) {
  useEffect(() => {
    onSelectionChange?.(ServiceStatusEnum.ALL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      className={cn("text-sm text-gray-6", className)}
      defaultSelectedKey={"all"}
      onSelectionChange={(key) =>
        onSelectionChange?.(
          key !== "all" ? (key as ServiceStatusEnum) : undefined,
        )
      }
      {...props}
    >
      <Label hidden={!showLabel}>Status</Label>
      <Button className="group flex w-full justify-between rounded-lg border border-white-3 bg-white-0 p-3">
        <span>
          Status:{" "}
          <span className="font-semibold">
            <SelectValue />
          </span>
        </span>
        <span aria-hidden="true">
          <ArrowDown2
            className="transition-all group-rac-hover:translate-y-1"
            size={20}
            variant="Bold"
          />
        </span>
      </Button>
      <Popover className="w-[--trigger-width] rounded-lg border border-white-3 bg-white-0 p-3 text-sm shadow-dialog">
        <ListBox className="*:rounded-lg *:p-2">
          <ListBoxItem className="hover:bg-black-0/10" id="all">
            Todos
          </ListBoxItem>
          <ListBoxItem
            className="hover:bg-black-0/10"
            id={ServiceStatusEnum.UNDER_ANALYSIS}
          >
            Em análise
          </ListBoxItem>
          <ListBoxItem
            className="hover:bg-black-0/10"
            id={ServiceStatusEnum.RUNNING}
          >
            Em execução
          </ListBoxItem>
          <ListBoxItem
            className="hover:bg-black-0/10"
            id={ServiceStatusEnum.DONE}
          >
            Concluído
          </ListBoxItem>
        </ListBox>
      </Popover>
    </Select>
  );
}
