import { Button, FileTrigger, FileTriggerProps } from "react-aria-components";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import { useMutationFileUpload } from "@/shared/hooks/api";
import { megabyte } from "@/shared/utils";

interface MultipleFileInput<T extends FieldValues> extends FileTriggerProps {
  control: Control<T>;
  name: Path<T>;
  maxFileSize?: number;
  fileInputRef: React.RefObject<HTMLInputElement>;
  onUploading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MultipleFileInput<T extends FieldValues>({
  name,
  control,
  maxFileSize = 5 * megabyte,
  fileInputRef,
  onUploading,
  ...fileTriggerProps
}: MultipleFileInput<T>) {
  const {
    field: { onChange, onBlur, value },
  } = useController({
    name,
    control,
  });

  const uploadMutation = useMutationFileUpload({
    maxFileSize,
    onSuccess: (data) => {
      onChange([...(value || []), ...data]);
      onBlur();
    },
    onSettled: () => onUploading?.(false),
  });

  const handleOnSelect = async (e: FileList | null) => {
    const files = Array.from(e ?? []);
    onUploading?.(true);
    await uploadMutation.mutateAsync(files);
  };

  return (
    <FileTrigger
      {...fileTriggerProps}
      onSelect={handleOnSelect}
      ref={fileInputRef}
      allowsMultiple
    >
      {/*HACK: Dummy button to satisfy warning*/}
      <Button className="hidden" />
    </FileTrigger>
  );
}
