import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsPreventivoGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UsePreventivesFilters = GetObjsPreventivoGetData;

export function usePreventives(initial: UsePreventivesFilters = {}) {
  const [filters, updateFilters] =
    useObjReducer<UsePreventivesFilters>(initial);

  const query = useQuery({
    queryKey: ["preventives", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.PreventivosService.getObjsPreventivoGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [query, { filters, updateFilters, handleSearchChange }] as const;
}
