import { useInfiniteQuery } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { Key } from "react-aria-components";
import { useIntersectionObserver } from "usehooks-ts";

import {
  ComboBoxInput,
  ComboBoxInputProps,
  InputItem,
} from "@/shared/components";
import { getPageCountFromTotal } from "@/shared/utils";

export interface ComboBoxApiResourceInputItem {
  id: Key;
  text: string;
}

export interface ComboBoxApiResourceInputProps<
  T extends object,
  TContent,
  TFilters,
> extends ComboBoxInputProps<T> {
  queryKey: string[];
  service: (
    data: {
      pageParam: number;
      inputText: string;
    },
    extraFilters?: Partial<TFilters>,
  ) => Promise<{ page: number; content: Array<TContent>; total: number }>;
  mapElementsKey: (element: TContent) => ComboBoxApiResourceInputItem;
  filters?: Partial<TFilters>;
  initialPageParam?: number;
  extraItems?: ComboBoxApiResourceInputItem[];
}
export function ComboBoxApiResourceInput<T extends object, TContent, TFilters>({
  isDisabled,
  queryKey,
  service,
  mapElementsKey,
  onSelectionChange,
  filters,
  initialPageParam = 0,
  extraItems = [],
  ...props
}: ComboBoxApiResourceInputProps<T, TContent, TFilters>) {
  const [inputText, setInputText] = useState("");
  const debouncedInputText = useDebounce(inputText, 300);

  const { data, isLoading, isError, fetchNextPage } = useInfiniteQuery({
    queryKey: [...queryKey, { ...filters, debouncedInputText }],
    initialPageParam,
    queryFn: async ({ pageParam }) =>
      await service({ pageParam, inputText: debouncedInputText }, filters),
    getNextPageParam: (lastPage, _) =>
      getPageCountFromTotal(lastPage.total) > lastPage.page
        ? lastPage.page + 1
        : undefined,
    placeholderData: (previousData) => previousData,
  });

  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  });

  useEffect(() => {
    if (isIntersecting) {
      fetchNextPage();
    }
  }, [fetchNextPage, isIntersecting]);

  useEffect(() => {
    if (!props.selectedKey) {
      setInputText("");
    }
  }, [props.selectedKey]);

  const _elements =
    data?.pages.flatMap((p) => p.content).map(mapElementsKey) ?? [];
  const _mapElementsKey = extraItems.concat(_elements);

  return (
    <ComboBoxInput
      {...props}
      isDisabled={isDisabled || isLoading || isError}
      inputValue={inputText}
      onInputChange={setInputText}
      onSelectionChange={(k) => {
        const keyText = _mapElementsKey.find((o) => o.id === k)?.text;

        if (!props.allowsCustomValue) {
          setInputText(keyText ?? "");
          onSelectionChange?.(k);
          return;
        }

        if (keyText) {
          setInputText(keyText);
        }

        onSelectionChange?.(k);
      }}
    >
      {_mapElementsKey.map((element, i) => (
        <InputItem
          key={element.id}
          id={element.id}
          ref={i === _mapElementsKey.length - 1 ? ref : null}
        >
          {element.text}
        </InputItem>
      ))}
    </ComboBoxInput>
  );
}
