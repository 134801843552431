import { useEffect, useState } from "react";
import {
  ProgressBar as AriaProgressBar,
  type ProgressBarProps as AriaProgressBarProps,
  Label,
} from "react-aria-components";

interface ProgressBarProps extends AriaProgressBarProps {
  label?: string;
  isFake?: boolean;
}

export function ProgressBar({
  label,
  isFake,
  value,
  ...props
}: ProgressBarProps) {
  const [progress, setProgress] = useState(value);

  useEffect(() => {
    if (isFake) {
      let currentProgress = 0;
      const intervalId = setInterval(() => {
        currentProgress += 2;
        setProgress(currentProgress);
        if (currentProgress >= 92) {
          clearInterval(intervalId);
        }
      }, 100);
    }
  }, [isFake]);

  return (
    <AriaProgressBar
      {...props}
      value={progress}
      className="text-white flex w-full flex-col gap-3"
    >
      {({ percentage, valueText }) => (
        <>
          <div className="flex text-sm font-medium text-primary">
            <Label className="flex-1">{label}</Label>
            <span className="value">{valueText}</span>
          </div>
          <div className="top-[50%] h-2 w-full translate-y-[-50%] transform rounded-full bg-primary-light-1">
            <div
              className="absolute top-[50%] h-2 translate-y-[-50%] transform rounded-full bg-primary-light-4"
              style={{ width: percentage + "%" }}
            />
          </div>
        </>
      )}
    </AriaProgressBar>
  );
}
