import { useRef, useState } from "react";
import { Control } from "react-hook-form";

import {
  FormListInput,
  ListInputElement,
  MultipleFileInput,
  ProgressBar,
} from "@/shared/components";
import { megabyte, z } from "@/shared/utils";

const DocumentListSchema = z.object({
  link: z.array(
    z.object({
      fileName: z.string(),
      key: z.string(),
    }),
  ),
});

type DocumentListContainer = z.infer<typeof DocumentListSchema>;

interface FormDocumentListProps<T extends DocumentListContainer> {
  className?: string;
  control: Control<T>;
}

export function FormDocumentList<T extends DocumentListContainer>({
  control,
  ...props
}: FormDocumentListProps<T>) {
  const _control = control as Control<DocumentListContainer>;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isUploading, setIsUploading] = useState(false);

  return (
    <FormListInput
      {...props}
      control={_control}
      name="link"
      onAddPress={() => fileInputRef.current?.click()}
      renderExtraContent={() =>
        isUploading && (
          <div className="p-3">
            <ProgressBar isFake />
          </div>
        )
      }
      resourceOptions={{
        word: "arquivo",
        pluralRadical: "s",
      }}
    >
      {(values, update) => (
        <>
          {values.length !== 0 ? (
            values.map((e, i) => (
              <ListInputElement
                key={e.key + i}
                onDelete={() =>
                  update(values.filter((_, index) => index !== i))
                }
              >
                {e.fileName}
              </ListInputElement>
            ))
          ) : (
            <p className="p-4 text-sm italic text-gray-8">Vazio</p>
          )}

          <MultipleFileInput
            fileInputRef={fileInputRef}
            control={_control}
            onUploading={setIsUploading}
            name="link"
            maxFileSize={50 * megabyte}
          />
        </>
      )}
    </FormListInput>
  );
}
