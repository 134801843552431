import { useQuery } from "@tanstack/react-query";

import { apiProvider } from "@/shared/services";

export function usePreventive(preventiveId: string) {
  return useQuery({
    queryKey: ["preventive", preventiveId],
    queryFn: () =>
      apiProvider.services.PreventivosService.getOneObjPreventivoIdGet({
        id: +preventiveId,
      }),
    enabled: !!preventiveId,
  });
}
