import { Handle, Navigate, RouteObject } from "react-router-dom";

import { AssistanceLayout } from "./containers";
import { ListCalls } from "./modules/calls/containers";
import { ListServices } from "./modules/services/containers";

export const assistanceRouter: RouteObject = {
  path: "assistencia",
  handle: {
    tabBaseUrl: "assistance",
  } as Handle,
  children: [
    { index: true, element: <Navigate to="chamados/" /> },
    {
      element: <AssistanceLayout />,
      children: [
        {
          path: "chamados",
          handle: {
            crumb: "Chamados",
            /* TODO: Add pending action */
          } as Handle,
          element: <ListCalls />,
        },
        {
          path: "servicos",
          handle: {
            crumb: "Serviços",
            /* TODO: Add pending action */
          } as Handle,
          element: <ListServices />,
        },
        {
          path: "agendamentos",
          handle: {
            crumb: "Agendamentos",
            /* TODO: Add pending action */
          } as Handle,
          element: <p className="m-4 text-xl font-bold">Em desenvolvimento</p>,
        },
        {
          path: "duvidas",
          handle: {
            crumb: "Dúvidas",
            /* TODO: Add pending action */
          } as Handle,
          element: <p className="m-4 text-xl font-bold">Em desenvolvimento</p>,
        },
        {
          path: "configuracoes",
          handle: {
            crumb: "Configurações",
            /* TODO: Add pending action */
          } as Handle,
          element: <p className="m-4 text-xl font-bold">Em desenvolvimento</p>,
        },
      ],
    },
  ],
};
