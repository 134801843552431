interface KanbanCardTagProps {
  tag?: string;
}

export function KanbanCardTag({ tag }: KanbanCardTagProps) {
  return (
    <div className="rounded-sm bg-error-20 px-3 py-1 text-xs font-medium capitalize text-error">
      {tag}
    </div>
  );
}
