import { useEffect } from "react";

import {
  InfiniteQueryHandler,
  KanbanCard,
  KanbanColumn,
  KanbanColumnProps,
} from "@/shared/components";
import { useSearchStore } from "@/shared/hooks";
import { useInfiniteMaintenances } from "@/shared/hooks/api";

export function KanbanMaintenanceColumn({ ...props }: KanbanColumnProps) {
  const { searchText } = useSearchStore();
  //TODO: Add correct filter
  const [{ flatData, isFetching, error }, { handleSearchChange }, { ref }] =
    useInfiniteMaintenances({
      text: "",
    });

  useEffect(() => {
    handleSearchChange(searchText);
  }, [handleSearchChange, searchText]);

  return (
    <KanbanColumn {...props}>
      <InfiniteQueryHandler
        isFetching={isFetching}
        error={error}
        isEmpty={!flatData?.length}
      >
        {flatData?.map((maintenance) => (
          <KanbanCard
            key={maintenance.id}
            color={"#006555"}
            title={maintenance.resumo}
            tag="Lorem"
            subtitle={"Manual Trial 101"}
            footerTitle={"Responsável"}
            footerValue={"Teste"}
            ref={maintenance.id === flatData.length - 1 ? ref : null}
          />
        ))}
      </InfiniteQueryHandler>
    </KanbanColumn>
  );
}
