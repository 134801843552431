import { z } from "@/shared/utils";

export const DocumentCreateFileSchema = z.object({
  documentCreationType: z.literal("file"),
  nome: z.string().trim().min(1, "Obrigatório"),
  manualId: z.number().int().nullish(),
  unidadeId: z.number().int().nullish(),
  paiId: z.number().int().nullish(),
  link: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .nonempty()
    .max(1),
});
export type DocumentCreateFile = z.infer<typeof DocumentCreateFileSchema>;

export const DocumentCreateDirectorySchema = z.object({
  documentCreationType: z.literal("directory"),
  nome: z.string().trim().min(1, "Obrigatório"),
  manualId: z.number().int().nullish(),
  unidadeId: z.number().int().nullish(),
  paiId: z.number().int().nullish(),
});
export type DocumentCreateDirectorySchema = z.infer<
  typeof DocumentCreateDirectorySchema
>;

export const DocumentCreateSchema = z.discriminatedUnion(
  "documentCreationType",
  [DocumentCreateFileSchema, DocumentCreateDirectorySchema],
);

export const DocumentCreateManyFilesSchema = z.object({
  documentCreationType: z.literal("file"),
  nome: z.string().trim(),
  manualId: z.number().int().nullish(),
  unidadeId: z.number().int().nullish(),
  paiId: z.number().int().nullish(),
  link: z.array(
    z.object({
      fileName: z.string(),
      key: z.string(),
    }),
  ),
});
export type DocumentCreateManyFiles = z.infer<
  typeof DocumentCreateManyFilesSchema
>;

export type DocumentCreate = z.infer<typeof DocumentCreateSchema>;
