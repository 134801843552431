import isMobilePhone from "validator/lib/isMobilePhone";
import isTaxID from "validator/lib/isTaxID";

import { falsyToNull, getDigits, z } from "@/shared/utils";

export const CreateApartmentNewClientSchema = z.object({
  clientCreationType: z.literal("new-client"),
  unidade: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    blocoId: z.number().int(),
    dataEntrega: z.date().nullish(),
    tipoClientId: z.number().int(),
  }),
  responsavel: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    email: z.string().email(),
    telefone: z
      .string()
      .refine((v) => isMobilePhone(v, "pt-BR"), "Número inválido"),
    cpf: z
      .string()
      .transform(getDigits)
      .refine((v) => isTaxID(v, "pt-BR"), "CPF inválido"),
  }),
});

export const CreateApartmentRegisteredClientSchema = z.object({
  clientCreationType: z.literal("registered-client"),
  unidade: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    blocoId: z.number().int(),
    dataEntrega: z.date().nullish(),
    donoId: falsyToNull(z.number().int().optional()),
  }),
});

export const CreateApartmentNoClientSchema = z.object({
  clientCreationType: z.literal("no-client"),
  unidade: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    blocoId: z.number().int(),
    dataEntrega: z.date().nullish(),
  }),
});

export const CreateApartmentSchema = z.discriminatedUnion(
  "clientCreationType",
  [
    CreateApartmentNewClientSchema,
    CreateApartmentRegisteredClientSchema,
    CreateApartmentNoClientSchema,
  ],
);

export type CreateApartment = z.infer<typeof CreateApartmentSchema>;
export type CreateApartmentNoClient = z.infer<
  typeof CreateApartmentNoClientSchema
>;
