import { nullishStringMask } from "@/shared/utils/masks";

interface KanbanCardTitleProps {
  color: string;
  title?: string | null;
}
export function KanbanCardTitle({ color, title }: KanbanCardTitleProps) {
  return (
    <span className="text-sm font-bold text-gray-8" style={{ color }}>
      {nullishStringMask(title)}
    </span>
  );
}
