import { Handle, RouteObject } from "react-router-dom";

import { editBlockAction } from "../edit/actions";
import { DevelopmentBlockEdit } from "../edit/containers";
import { apartmentCreateAction } from "./actions";
import { ApartmentCreate, ApartmentsList, BlockData } from "./containers";
import { BlockLayout } from "./layout";
import { apartmentDetailRouter } from "./modules/apartment/router";

export const blockDetailRouter: RouteObject = {
  path: "detalhes/:blockId",
  handle: {
    crumb: "Detalhes do bloco",
    tabBaseUrl: "block",
    action: "get.block",
  } as Handle,
  children: [
    {
      element: <BlockLayout />,
      children: [
        {
          index: true,
          element: <BlockData />,
        },
        {
          path: "apartamento",
          handle: {
            crumb: "Lista de apartamentos",
            action: "list.apartment",
          } as Handle,
          element: <ApartmentsList />,
        },
      ],
    },
    {
      path: "editar",
      handle: {
        crumb: "Editar bloco",
        action: "update.block",
      } as Handle,
      action: editBlockAction,
      element: <DevelopmentBlockEdit />,
    },
    {
      path: "apartamento",
      children: [
        {
          path: "cadastrar",
          handle: {
            crumb: "Cadastrar apartamento",
            action: "create.apartment",
          } as Handle,
          action: apartmentCreateAction,
          element: <ApartmentCreate />,
        },
        apartmentDetailRouter,
      ],
    },
  ],
};
