export const permissions = {
  create: {
    apartment: "unidades.create_obj",
    block: "blocos.create_obj",
    client: "clientes.create_cliente",
    company: "construtoras.create_obj",
    contact: "contatos.create_obj",
    development: "empreendimentos.create_obj",
    document: "arquivo.create_pasta",
    failure: "falha.create_obj",
    faq: "faq.create_obj",
    nbr: "nbr.create_obj",
    maintenance: "preventivo.create_obj",
    manual: "manual.create_obj",
    memorial: "memorial.create_obj",
    product: "produto.create_obj",
    post: "post.create_obj",
    step: "etapa.create_obj",
    subsystem: "subsistema.create_obj",
    system: "sistema.create_obj",
    topic: "topico.create_obj",
    user: "usuarios.create_obj",
    warranty: "garantia.create_obj",
    worker: "colaborador.create_colaborador",
  },
  get: {
    apartment: "unidades.get_one_obj",
    block: "blocos.get_one_obj",
    client: "clientes.get_cliente",
    company: "construtoras.get_one_obj",
    contact: "contatos.get_one_obj",
    development: "empreendimentos.get_one_obj",
    failure: "falha.get_one_obj",
    faq: "faq.get_one_obj",
    nbr: "nbr.get_one_obj",
    maintenance: "preventivo.get_one_obj",
    manual: "manual.get_one_obj",
    permission: "papeis.get_one_obj",
    product: "produto.get_one_obj",
    profile: "usuarios.get_profile",
    post: "post.get_one_obj",
    subsystem: "subsistema.get_one_obj",
    system: "sistema.get_one_obj",
    topic: "topico.get_one_obj",
    user: "usuarios.get_one_obj",
    warranty: "garantia.get_one_obj",
    worker: "colaborador.get_colaborador",
  },
  list: {
    apartment: "unidades.get_objs",
    block: "blocos.get_objs",
    client: "clientes.get_clientes",
    company: "construtoras.get_objs",
    contact: "contatos.get_objs",
    development: "empreendimentos.get_objs",
    document: "arquivo.get_objs",
    failure: "falha.get_objs",
    faq: "faq.get_objs",
    maintenance: "preventivo.get_objs",
    manual: "manual.get_objs",
    memorial: "memorial.get_objs",
    nbr: "nbr.get_objs",
    permission: "papeis.get_objs",
    product: "produto.get_objs",
    post: "post.get_objs",
    step: "etapa.get_objs",
    subsystem: "subsistema.get_objs",
    system: "sistema.get_objs",
    topic: "topico.get_objs",
    user: "usuarios.get_objs",
    warranty: "garantia.get_objs",
    worker: "colaborador.get_colaboradores",
  },
  update: {
    apartment: "unidades.update_obj",
    block: "blocos.update_obj",
    client: "clientes.update_cliente",
    company: "construtoras.update_obj",
    contact: "contatos.update_obj",
    development: "empreendimentos.update_obj",
    document: "arquivo.update_obj",
    failure: "falha.update_obj",
    faq: "faq.update_obj",
    nbr: "nbr.update_obj",
    maintenance: "preventivo.update_obj",
    manual: "manual.update_obj",
    memorial: "memorial.update_obj",
    product: "produto.update_obj",
    profile: "usuarios.update_self",
    post: "post.update_obj",
    step: "etapa.update_obj",
    subsystem: "subsistema.update_obj",
    system: "sistema.update_obj",
    topic: "topico.update_obj",
    user: "usuarios.update_obj",
    warranty: "garantia.update_obj",
    worker: "colaborador.update_colaborador",
  },
  delete: {
    document: "arquivo.delete_obj",
  },
  bulk: {
    create: {
      block: "acoplados.post_unidades_lote",
    },
  },
} as const;
