import { forwardRef } from "react";

import { KanbanCardFooter } from "./kanban-card-footer";
import { KanbanCardFooterTitle } from "./kanban-card-footer-tile";
import { KanbanCardFooterValue } from "./kanban-card-footer-value";
import { KanbanCardHeader } from "./kanban-card-header";
import { KanbanCardSubtitle } from "./kanban-card-subtitle";
import { KanbanCardTag } from "./kanban-card-tag";
import { KanbanCardTitle } from "./kanban-card-title";

interface KanbanCardProps {
  color: string;
  title?: string | null;
  subtitle?: string;
  tag?: string;
  footerTitle?: string;
  footerValue?: string;
}

export const KanbanCard = forwardRef<HTMLDivElement, KanbanCardProps>(
  ({ color, title, subtitle, tag, footerTitle, footerValue }, ref) => {
    const colorWithOpacity = `${color}1A`;

    return (
      <div
        className="mx-3 w-64 rounded-md p-3 pb-5"
        style={{ backgroundColor: colorWithOpacity }}
        ref={ref}
      >
        <KanbanCardHeader color={color}>
          <div className="flex flex-col">
            <KanbanCardTitle color={color} title={title} />
            <KanbanCardSubtitle subtitle={subtitle} />
          </div>
          {tag && <KanbanCardTag tag={tag} />}
        </KanbanCardHeader>

        {footerTitle && (
          <KanbanCardFooter>
            <KanbanCardFooterTitle footerTitle={footerTitle} />
            <KanbanCardFooterValue footerValue={footerValue} />
          </KanbanCardFooter>
        )}
      </div>
    );
  },
);
