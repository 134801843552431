import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsServicoGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseServicesFilters = GetObjsServicoGetData;

export function useServices(initial: UseServicesFilters = {}) {
  const [filters, updateFilters] = useObjReducer<UseServicesFilters>(initial);
  const query = useQuery({
    queryKey: ["services", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.ServicosService.getObjsServicoGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [
    query,
    {
      filters,
      updateFilters,
      handleSearchChange,
    },
  ] as const;
}
