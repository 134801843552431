import {
  Can,
  Card,
  EditLink,
  PageTitle,
  QueryHandler,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useClient, useMutationClientUpdate } from "@/shared/hooks/api";
import { displayDateHourMin } from "@/shared/utils";

import { ClientDetailError, ClientDetailLoading } from "../components";

export function ClientData() {
  const { clientId } = useRequiredParams();
  const clientQuery = useClient(clientId);
  const mutationClientUpdate = useMutationClientUpdate();

  return (
    <QueryHandler
      {...clientQuery}
      renderLoading={ClientDetailLoading}
      renderError={(error) => <ClientDetailError error={error} />}
    >
      {(data) => (
        <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="flex flex-col justify-center">
                <div className="flex items-center gap-2">
                  <PageTitle primary>{data?.nome}</PageTitle>
                  <Can I="update.client">
                    <EditLink />
                  </Can>
                </div>
                <div className="flex gap-6 text-xs text-primary">
                  <p>
                    Data de aceitação de termos de uso:{" "}
                    <span className="font-semibold">
                      {displayDateHourMin(data?.aceitacao)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <Can I="update.client">
              <SwitchStatusButton
                className="self-start justify-self-end"
                isDisabled={mutationClientUpdate.isPending}
                isSelected={data?.flagAtivo}
                onChange={(status) =>
                  mutationClientUpdate.mutateAsync({
                    id: +clientId,
                    requestBody: { flagAtivo: status },
                  })
                }
              />
            </Can>
          </div>
          <Separator />
          <p className="mb-3 text-sm text-black-2">Contato</p>
          <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
            <div>
              <p>Nome</p>
              <p className="font-bold text-primary">{data?.nome}</p>
            </div>

            <div>
              <p>Email</p>
              <p className="font-bold text-primary">{data?.email}</p>
            </div>
          </div>
        </Card>
      )}
    </QueryHandler>
  );
}
