import { useEffect } from "react";
import { useDebounceValue } from "usehooks-ts";

import { TextInput, TextInputProps } from "..";

export interface ResponsibleFilterProps
  extends Omit<TextInputProps, "styleType" | "label"> {
  onDebounceChange?: (search: string) => void;
}

export function ResponsibleFilter({
  onDebounceChange,
  ...props
}: ResponsibleFilterProps) {
  const [debouncedSearch, setSearch] = useDebounceValue("", 500);

  useEffect(() => {
    onDebounceChange?.(debouncedSearch);
  }, [debouncedSearch, onDebounceChange]);

  return (
    <TextInput
      styleType="filter"
      label="Responsável"
      onChange={(e) => setSearch(e)}
      {...props}
    />
  );
}
