import { Skeleton } from "..";

export function KanbanColumnLoading() {
  return (
    <>
      <Skeleton className="h-64 w-1/4" />
      <Skeleton className="h-64 w-1/4" />
      <Skeleton className="h-64 w-1/4" />
      <Skeleton className="h-64 w-1/4" />
    </>
  );
}
