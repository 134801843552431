import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { apiProvider } from "@/shared/services";

export function useUserType() {
  const userTypeQuery = useQuery({
    queryKey: ["userType"],
    queryFn:
      apiProvider.services.PapeisService
        .getPapeisEngegroutPapeisTiposPapeisEngegroutGet,
  });

  const getNameById = useCallback(
    (id?: number) => userTypeQuery.data?.find((e) => id === e.id)?.nome,
    [userTypeQuery.data],
  );

  return { ...userTypeQuery, getNameById };
}
