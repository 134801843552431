import {
  AnimatedContainer,
  Breadcrumbs,
  Can,
  Card,
  EditLink,
  PageTitle,
  QueryHandler,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useClientType,
  useMutationPreventiveUpdate,
  usePreventive,
  useTimeUnit,
} from "@/shared/hooks/api";
import { sanitize } from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";

import {
  MaintenanceDetailError,
  MaintenanceDetailLoading,
} from "../components";

export function MaintenanceDetail() {
  const { maintenanceId } = useRequiredParams();
  const maintenanceQuery = usePreventive(maintenanceId);
  const { findClientById } = useClientType();
  const { findTimeQueryById } = useTimeUnit();

  const mutationMaintenanceUpdate = useMutationPreventiveUpdate();
  return (
    <AnimatedContainer className="m-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Detalhes do preventivo</PageTitle>
      </div>
      <QueryHandler
        {...maintenanceQuery}
        renderLoading={MaintenanceDetailLoading}
        renderError={(error) => <MaintenanceDetailError error={error} />}
      >
        {(data) => (
          <Card className="shadow-header">
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <PageTitle primary>{data?.nome}</PageTitle>
                <Can I="update.maintenance">
                  <EditLink />
                </Can>
              </div>
              <Can I="update.maintenance">
                <SwitchStatusButton
                  isDisabled={mutationMaintenanceUpdate.isPending}
                  isSelected={data?.flagAtivo}
                  onChange={(status) =>
                    mutationMaintenanceUpdate.mutateAsync({
                      id: +maintenanceId,
                      requestBody: { flagAtivo: status },
                    })
                  }
                />
              </Can>
            </div>
            <Separator />
            {data?.descricao && (
              <div
                className="tiptap text-black-0 [&>p]:min-h-6"
                dangerouslySetInnerHTML={{
                  __html: sanitize(data.descricao),
                }}
              />
            )}
            <p className="mb-3 text-sm text-black-2">Dados gerais</p>
            <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
              <div>
                <p>Frequência</p>
                <p className="font-bold text-primary">
                  {nullishStringMask(data?.frequencia?.toString())}
                </p>
              </div>

              <div>
                <p>Unidade de tempo</p>
                <p className="font-bold text-primary">
                  {nullishStringMask(findTimeQueryById(data))}
                </p>
              </div>

              <div>
                <p>Tipo do cliente</p>
                <p className="font-bold text-primary">
                  {nullishStringMask(findClientById(data?.tipoClienteId))}
                </p>
              </div>
            </div>
          </Card>
        )}
      </QueryHandler>
    </AnimatedContainer>
  );
}
