import { Handle, RouteObject } from "react-router-dom";

import { DocumentList } from "@/shared/modules/document/containers";

import { editApartmentAction } from "./actions";
import {
  ApartmentDetail,
  ApartmentDetailLayout,
  ApartmentEdit,
} from "./containers";

export const apartmentDetailRouter: RouteObject = {
  path: "detalhes/:apartmentId",
  handle: {
    crumb: "Detalhes do apartamento",
    tabBaseUrl: "apartment",
    action: "get.apartment",
  } as Handle,
  children: [
    {
      element: <ApartmentDetailLayout />,
      children: [
        {
          index: true,
          element: <ApartmentDetail />,
        },

        {
          path: "arquivos",
          handle: {
            crumb: "Lista de arquivos",
            action: "list.document",
          } as Handle,
          element: <DocumentList />,
        },
      ],
    },
    {
      path: "editar",
      handle: {
        crumb: "Editar apartamento",
        action: "update.apartment",
      } as Handle,
      action: editApartmentAction,
      element: <ApartmentEdit />,
    },
  ],
};
