import { Key, ListBoxItem, Select } from "react-aria-components";

import { SelectInput, SelectInputProps } from ".";
import { cn } from "../utils";

interface Option<T> {
  id: string;
  label: string;
  value: T;
}

export interface FilterSelectInputProps<T>
  extends Omit<SelectInputProps<typeof Select>, "onSelectionChange"> {
  options: Option<T>[];
  defaultValue?: T;
  onSelectionChange?: (key: Key, value?: T) => void;
}

export function FilterSelectInput<T>({
  className,
  options,
  onSelectionChange,
  label = "Filtrar por",
  ...props
}: FilterSelectInputProps<T>) {
  return (
    <SelectInput
      {...props}
      className={cn("text-sm text-gray-6", className)}
      styleType="filter"
      label={label}
      onSelectionChange={(key) =>
        onSelectionChange?.(
          key,
          options.find((option) => option.id === key)?.value,
        )
      }
    >
      {options.map((option) => (
        <ListBoxItem
          key={option.id}
          className="hover:bg-black-0/10"
          id={option.id}
        >
          {option.label}
        </ListBoxItem>
      ))}
    </SelectInput>
  );
}
