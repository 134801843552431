import { useParams } from "react-router-dom";

import { AnimatedContainer, FilterAndCreate } from "@/shared/components";
import { usePreventives } from "@/shared/hooks/api";

import { MaintenanceTable } from "../components";

export function MaintenanceList() {
  const { subsystemId, productId } = useParams();
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    usePreventives(
      productId
        ? { produtoId: +productId }
        : {
            subsistemaId: +subsystemId!,
          },
    );

  return (
    <AnimatedContainer className="space-y-4">
      <FilterAndCreate
        resourceName="preventivo"
        onSearchChange={handleSearchChange}
        createAction="create.maintenance"
      />
      <MaintenanceTable
        search={filters.text ?? undefined}
        rowsData={data?.content}
        isLoading={isLoading}
        pagination={{
          currentPage: filters.page ?? 1,
          pageCount: Math.ceil((data?.total ?? 0) / PAGE_COUNT),
          onPageChange: (page) => updateFilters({ page }),
        }}
      />
    </AnimatedContainer>
  );
}
