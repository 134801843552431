import isMobilePhone from "validator/lib/isMobilePhone";
import isPostalCode from "validator/lib/isPostalCode";
import isTaxID from "validator/lib/isTaxID";

import { falsyToNull, getDigits, z } from "@/shared/utils";
import { generateMultiStepSchema } from "@/shared/utils/generate-multi-step-schema";
import { generateStepSchema } from "@/shared/utils/generate-step-schema";

export const CompanyInfoSchema = z.object({
  nome: z.string().trim().min(1, "Obrigatório"),
  cnpj: z
    .string()
    .transform(getDigits)
    .refine((v) => isTaxID(v, "pt-BR"), "CNPJ inválido"),
  email: z.string().email().nullish(),
  site: z
    .string()
    .startsWith("https://", "Precisa começar com https://")
    .url()
    .trim()
    .nullish(),
  categoriaConstrutoraId: z.number().int(),
  foto: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .nullish(),
  telefone: z
    .string()
    .nullish()
    .refine((v) => !v || isMobilePhone(v, "pt-BR"), "Número inválido"),
  whatsapp: z
    .string()
    .nullish()
    .refine((v) => !v || isMobilePhone(v, "pt-BR"), "Número inválido"),
  responsavel: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    cpf: z
      .string()
      .nullish()
      .transform((v) => (v ? getDigits(v) : v))
      .refine((v) => !v || isTaxID(v, "pt-BR"), "CPF inválido"),
    email: z.string().email(),
    telefone: z
      .string()
      .nullish()
      .refine((v) => !v || isMobilePhone(v, "pt-BR"), "Número inválido"),
  }),
});

export const CompanyAddressSchema = z.object({
  cep: z
    .string()
    .refine((v) => !v || isPostalCode(v, "BR"), "Cep inválido")
    .transform((v) => (v ? getDigits(v) : null))
    .nullish(),
  estado: falsyToNull(z.string().trim().nullish()),
  cidade: falsyToNull(z.string().trim().nullish()),
  bairro: falsyToNull(z.string().trim().nullish()),
  rua: falsyToNull(z.string().trim().nullish()),
  numero: falsyToNull(z.string().trim().nullish()),
  complemento: falsyToNull(z.string().trim().nullish()),
});

export const CompanyCreateSchema = z.object({
  ...CompanyInfoSchema.shape,
  ...CompanyAddressSchema.shape,
});

const CompanyInfoStepSchema = generateStepSchema(CompanyInfoSchema, 0);

export const CompanyAddressStepSchema = generateStepSchema(
  CompanyAddressSchema,
  1,
);

export const CompanyCreateFormSchema = generateMultiStepSchema([
  CompanyInfoStepSchema,
  CompanyAddressStepSchema,
]);

export type CompanyInfo = z.infer<typeof CompanyInfoSchema>;
export type CompanyAddress = z.infer<typeof CompanyAddressSchema>;
export type CompanyCreate = z.infer<typeof CompanyCreateSchema>;

export type CompanyCreateForm = z.infer<typeof CompanyCreateFormSchema>;
