import { Add } from "iconsax-react";

import { Button } from "@/shared/components";
import { cn, pluralize } from "@/shared/utils";

export interface ListInputProps<TValue> {
  className?: string;
  values: TValue[];
  resourceOptions: {
    word: string;
    pluralRadical?: string;
    singularRadical?: string;
    isMasculine?: boolean;
  };
  emptyComponent?: React.ReactNode;
  onAddPress?: () => void;
  children?: (values: TValue[]) => React.ReactNode;
  renderExtraContent?: () => React.ReactNode;
}

export function ListInput<TValues>({
  className,
  values,
  resourceOptions,
  emptyComponent,
  onAddPress,
  children,
  renderExtraContent,
}: ListInputProps<TValues>) {
  return (
    <div className={cn("flex flex-col rounded-xl shadow-header", className)}>
      <div className="flex items-center justify-between rounded-t-xl bg-primary-light-1 px-4 py-2">
        <p className="text-sm font-bold text-primary">
          {values.length}{" "}
          {pluralize(
            resourceOptions.word,
            values.length,
            resourceOptions.pluralRadical,
            resourceOptions.singularRadical,
          )}{" "}
          {pluralize(
            `adicionad${resourceOptions.isMasculine ? "o" : "a"}`,
            values.length,
          )}
        </p>
        <Button
          className="gap-4 text-sm"
          styleType="default"
          onPress={onAddPress}
        >
          <Add />
          Adicionar{" "}
          {resourceOptions.word + (resourceOptions.singularRadical || "")}
        </Button>
      </div>
      <div className="flex min-h-52 flex-1 flex-col overflow-y-scroll rounded-b-xl">
        {renderExtraContent?.()}
        {values.length === 0 && emptyComponent
          ? emptyComponent
          : children?.(values)}
      </div>
    </div>
  );
}
