import { Separator } from "../separator";

export interface KanbanColumnProps extends React.PropsWithChildren {
  status: string;
  color: string;
  columnLength: number;
}

export function KanbanColumn({
  status,
  color,
  columnLength,
  children,
}: KanbanColumnProps) {
  const colorWithOpacity = `${color}1A`;
  return (
    <div className="flex max-h-full flex-col rounded-lg bg-white-1 py-3.5">
      <div className="px-3">
        <div className="flex items-center gap-4">
          <span className="text-sm font-medium capitalize" style={{ color }}>
            {status}
          </span>
          <div
            className="rounded-md px-2 py-1 text-xs font-semibold"
            style={{ backgroundColor: colorWithOpacity, color }}
          >
            {columnLength}
          </div>
        </div>
        <Separator className="mb-3 mt-4 border-gray-1" />
      </div>

      <div className="scrollbar-thin flex flex-col gap-3 overflow-y-auto overflow-x-hidden">
        {children}
      </div>
    </div>
  );
}
