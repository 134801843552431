import { useEffect } from "react";

import { Spinner } from "./spinner";
import { toastQueue } from "./toast";

interface InfiniteQueryHandlerProps {
  isEmpty: boolean;
  children: React.ReactNode | undefined;
  error: Error | null;
  isFetching?: boolean;
  renderEmpty?: () => React.ReactElement;
}

export function InfiniteQueryHandler({
  isEmpty,
  error,
  children,
  isFetching,
  renderEmpty,
}: InfiniteQueryHandlerProps) {
  useEffect(() => {
    if (error) {
      toastQueue.add({ message: error.message, type: "error" });
    }
  }, [error]);

  if (isFetching && isEmpty) {
    return (
      <div>
        <Spinner className="mx-auto" />
      </div>
    );
  }

  if (isEmpty && renderEmpty) {
    return renderEmpty();
  }

  if (isEmpty) {
    return (
      <div className="py-4 text-center text-base font-semibold">
        Nenhum dado disponível.
      </div>
    );
  }

  return (
    <>
      {children}

      {isFetching && <Spinner className="mx-auto" />}
    </>
  );
}
