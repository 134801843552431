import { nullishStringMask } from "@/shared/utils/masks";

interface KanbanCardFooterValueProps {
  footerValue?: string;
}

export function KanbanCardFooterValue({
  footerValue,
}: KanbanCardFooterValueProps) {
  return (
    <span className="text-xs font-normal text-gray-10">
      {nullishStringMask(footerValue)}
    </span>
  );
}
