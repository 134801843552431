import { Separator } from "..";

interface KanbanCardHeaderProps extends React.PropsWithChildren {
  color: string;
}

export function KanbanCardHeader({ color, children }: KanbanCardHeaderProps) {
  return (
    <>
      <div className="flex items-center justify-between">{children}</div>
      <Separator style={{ borderColor: color }} className="my-1.5" />
    </>
  );
}
