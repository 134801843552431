import { useCallback } from "react";

import {
  FilterAndCreate,
  KanbanColumnLoading,
  KanbanContainer,
  QueryHandler,
  UnauthorizedModal,
} from "@/shared/components";
import { useSearchStore, useSwitch } from "@/shared/hooks";
import { useMaintenancesStatus } from "@/shared/hooks/api";

import { KanbanMaintenanceColumn } from "../components";

export function ListCalls() {
  const { updateText } = useSearchStore();

  const [maintenanceStatusQuery] = useMaintenancesStatus({
    text: "",
  });

  const handleDebounceChange = useCallback(updateText, [updateText]);

  const { isOn, turnOn, setOpen } = useSwitch(false);

  return (
    <div className="flex flex-col space-y-4 pt-4">
      <FilterAndCreate
        resourceName="chamado"
        onSearchChange={handleDebounceChange}
        createOnPress={turnOn}
        createAction="create.maintenance"
      />
      <div className="flex gap-3">
        <QueryHandler
          {...maintenanceStatusQuery}
          renderLoading={KanbanColumnLoading}
        >
          {(data) => (
            <KanbanContainer>
              {data?.content.map((column) => (
                <KanbanMaintenanceColumn
                  key={column.id}
                  status={column.nome}
                  color={"#006555"}
                  columnLength={1}
                />
              ))}
            </KanbanContainer>
          )}
        </QueryHandler>
      </div>
      <UnauthorizedModal isOpen={isOn} onOpenChange={setOpen} />
    </div>
  );
}
