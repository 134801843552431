import { useParams } from "react-router-dom";

import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";

import { ClientCreateForm } from "../components";

export function ClientCreate() {
  const { developmentId } = useParams();
  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Cliente</PageTitle>
      </div>
      <ClientCreateForm
        redirectTo="../"
        useFormProps={{
          defaultValues: {
            apartmentCreationType: developmentId
              ? "new-apartment"
              : "no-apartment",
          },
        }}
        empreendimentoId={developmentId ? +developmentId : undefined}
      />
    </AnimatedContainer>
  );
}
